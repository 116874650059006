import { FETCH_LOGS } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_LOGS:
      let newValue = state;
      newValue[action.payload["fileName"]] = action.payload["values"];
      return newValue;
    default:
      return state;
  }
};
