import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import M from 'materialize-css/dist/js/materialize.min.js';
import lo from 'lodash';
import ModalVideo from 'react-modal-video';

import { fetchMovies, saveMovie, setLoading, deleteMovie } from '../actions';

class Movies extends Component {
  state = {
    movie: {},
    showSeen: false,
    loadedItems: [],
    videoId: 'oOoZgb7mycg',
    isOpen: false,
  };

  componentDidMount() {
    this.props.fetchMovies({ seen: false });
    this.props.setLoading(true);

    var elem = document.querySelector('.sidenav-descr');
    M.Sidenav.init(elem, {
      edge: 'right',
      inDuration: 250,
    });
  }

  componentWillUnmount() {
    this.props.setLoading(false);
  }

  onLoad(feedItem) {
    this.setState(
      ({ loadedItems }) => {
        return { loadedItems: loadedItems.concat(feedItem) };
      },
      () => {
        if (this.state.loadedItems.length === this.props.movies.length) this.props.setLoading(false);
      }
    );
  }

  removeLoaded(item) {
    this.setState(({ loadedItems }) => {
      return {
        loadedItems: lo.remove(this.state.loadedItems, (x) => x !== item),
      };
    });
  }

  renderMovies() {
    return (
      <TransitionGroup className='movie-list'>
        {this.state.loadedItems.map((movie) => {
          let imageFilename = movie.photo
            ? movie.photo.substring(movie.photo.lastIndexOf('/') + 1)
            : 'no_movie_img.jpg';

          return (
            <CSSTransition key={movie._id} classNames='fade' timeout={300}>
              <div className='col l2 m3 s4 boxInner'>
                <div className='card'>
                  <div className='card-image waves-effect waves-block waves-light hoverable'>
                    <img
                      className='responsive-img scale-transition sidenav-trigger'
                      data-target='descr-nav'
                      src={`/api/images/${imageFilename}`}
                      alt={movie.name}
                      onClick={() =>
                        this.setState({
                          movie,
                        })
                      }
                    />
                    <div
                      className='result-details-movie sidenav-trigger'
                      data-target='descr-nav'
                      onClick={() =>
                        this.setState({
                          movie,
                        })
                      }
                    >
                      {movie.name || ''}
                    </div>
                    <a
                      className='responsive-img btn-floating waves-effect waves-light red right btn-small'
                      onClick={() => {
                        this.removeLoaded(movie);
                        this.props.saveMovie(movie, this.state.showSeen);
                      }}
                    >
                      <i className='material-icons'>{movie.seen ? 'add' : 'done'}</i>
                    </a>
                    <a
                      className='btn-floating-bottom responsive-img red waves-effect'
                      onClick={() => {
                        this.props.deleteMovie({ movie_id: movie._id });
                      }}
                    >
                      <i className='material-icons'>delete</i>
                    </a>
                    {movie.trailer ? (
                      <a
                        className='btn-floating responsive-img red btn-small waves-effect margin-for-button'
                        onClick={() => {
                          this.setState({
                            videoId: movie.trailer.split('?v=')[1],
                            isOpen: true,
                          });
                        }}
                      >
                        <i className='material-icons'>movie</i>
                      </a>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    );
  }

  render() {
    const { movie } = this.state;
    return (
      <div className='row pad right-align'>
        <ModalVideo
          channel='youtube'
          isOpen={this.state.isOpen}
          videoId={this.state.videoId}
          onClose={() => this.setState({ isOpen: false })}
        />
        <label style={{ paddingRight: '10px' }}>
          <input
            type='checkbox'
            className='filled-in'
            onChange={() => {
              this.setState({ showSeen: !this.state.showSeen }, () => {
                this.setState({ loadedItems: [] });
                this.props.setLoading(true);
                this.props.fetchMovies({ seen: this.state.showSeen });
              });
            }}
            checked={this.state.showSeen ? 'checked' : ''}
          />
          <span>Show Watched</span>
        </label>
        <div className='row'>
          {this.renderMovies()}
          <div className='hideme'>
            {this.props.movies.map((movie) => {
              let imageFilename = movie.photo.substring(movie.photo.lastIndexOf('/') + 1);
              if (!movie.seen || this.state.showSeen) {
                return (
                  <img
                    src={`/api/images/${imageFilename}`}
                    alt={imageFilename}
                    key={movie._id}
                    onLoad={this.onLoad.bind(this, movie)}
                  />
                );
              } else return <div key={movie._id} />;
            })}
          </div>

          <ul id='descr-nav' className={`sidenav sidenav-descr `}>
            <li className='card-title'>{movie.name || ''}</li>
            <li className='card-title'>{movie.year || ''}</li>
            <li>
              <div className='divider' />
            </li>
            <li>
              <p className='desc-text'>{movie.plot || ''}</p>
            </li>
            <li>
              <div className='divider' />
            </li>
            <li className='desc-text'>
              <p className='right-align'>Score: {movie.ratings || ''}</p>
            </li>
            <li>
              <div className='divider' />
            </li>
            <li className='desc-text'>
              <p className='right-align'>Date added: {movie.date ? movie.date.substring(0, 10) : ''}</p>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ movies }) => ({ movies }),
  {
    fetchMovies,
    saveMovie,
    setLoading,
    deleteMovie,
  }
)(Movies);
