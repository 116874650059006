import React, { Component } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import { Link } from "react-router-dom";

import { SIDENAV_COLOR } from "../constants";

class SideNavigation extends Component {
  componentDidMount() {
    var elem = document.querySelector(".sidenav");
    M.Sidenav.init(elem, {
      edge: "left",
      inDuration: 250
    });
  }

  render() {
    const { pathname } = this.props.history.location;

    return (
      <div>
        <a
          href=" "
          data-target="slide-out"
          className="sidenav-trigger"
          style={{ position: "fixed" }}
        >
          <i
            className="material-icons"
            style={{ paddingTop: "10px", color: "orange" }}
          >
            menu
          </i>
        </a>
        <ul
          id="slide-out"
          className={`sidenav sidenav-fixed z-depth-3 ${SIDENAV_COLOR}`}
          style={{ marginTop: "65px", width: "210px" }}
        >
          <li
            className={
              pathname === "/dashboard/movies"
                ? "active sidenav-close"
                : "sidenav-close"
            }
          >
            <Link to="/dashboard/movies" className="waves-effect">
              <i className="material-icons">movie</i>
              Movies
            </Link>
          </li>
          <li
            className={
              pathname === "/dashboard/episodes"
                ? "active sidenav-close"
                : "sidenav-close"
            }
          >
            <Link to="/dashboard/episodes" className="waves-effect">
              <i className="material-icons">tv</i>
              Episodes
            </Link>
          </li>
          <li
            className={
              pathname === "/dashboard/series"
                ? "active sidenav-close"
                : "sidenav-close"
            }
          >
            <Link to="/dashboard/series" className="waves-effect">
              <i className="material-icons">video_label</i>
              Series
            </Link>
          </li>
          <li
            className={
              pathname === "/dashboard/logs"
                ? "active sidenav-close"
                : "sidenav-close"
            }
          >
            <Link to="/dashboard/logs" className="waves-effect">
              <i className="material-icons">chrome_reader_mode</i>
              Logs
            </Link>
          </li>
          <li
            className={
              pathname === "/dashboard/config"
                ? "active sidenav-close"
                : "sidenav-close"
            }
          >
            <Link to="/dashboard/config" className="waves-effect">
              <i className="material-icons">settings</i>
              Control Panel
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default SideNavigation;

//TODO: Make side navigation not get in all the way so icons are visible
