export const FETCH_USER = 'fetch_user';
export const SET_SUBMIT = 'set_submit';
export const FETCH_MOVIES = 'fetch_movies';
export const FETCH_SERIES = 'fetch_series';
export const DELETE_SERIES = 'delete_series';
export const DELETE_MOVIE = 'delete_movie';
export const DELETE_EPISODE = 'delete_episode';
export const SAVE_MOVIE = 'save_movie';
export const SAVE_SERIES = 'save_series';
export const REGISTER_USER = 'register_user';
export const FETCH_EPISODES = 'fetch_episodes';
export const FETCH_LOGS = 'fetch_logs';
export const SET_LOADING = 'set_loading';
