import axios from 'axios';
import * as types from './types';
import showToast from '../components/toast';

export const fetchUser = () => async (dispatch, state) => {
  const res = await axios.get(`/api/current_user`);
  dispatch({ type: types.FETCH_USER, payload: res.data });
};

export const submitCredentials = (values, history) => async (dispatch, state) => {
  if (!state.submitting) {
    dispatch(setSubmitting(true));
    const res = await axios.post('/api/login', values);
    dispatch({ type: types.FETCH_USER, payload: res.data });
    dispatch(setSubmitting(false));
    history.push('/dashboard/movies');
  }
};

export const setSubmitting = (value) => ({
  type: types.SET_SUBMIT,
  payload: value,
});

export const setLoading = (value) => ({
  type: types.SET_LOADING,
  payload: value,
});

export const fetchMovies = (values) => async (dispatch, state) => {
  if (!state.submitting) {
    dispatch(setSubmitting(true));
    const res = await axios.post(`/api/movies`, values);
    dispatch({ type: types.FETCH_MOVIES, payload: res.data });
    dispatch(setSubmitting(false));
  }
};

export const saveMovie = (values, seen) => async (dispatch, state) => {
  const res = await axios.post('/api/savemovie', values);
  showToast(res.data);
  await dispatch(fetchMovies({ seen }));
};

export const registerUser = (values) => async (dispatch, state) => {
  if (!state.submitting) {
    dispatch(setSubmitting(true));
    const res = await axios.post('/api/register', values);
    showToast(res.data);
    dispatch(setSubmitting(false));
  }
};

export const saveSeries = (values, fetchSeriesOnly) => async (dispatch, state) => {
  const { series_id } = values;
  const res = await axios.post('/api/tvsave', values);
  showToast(res.data);
  fetchSeriesOnly ? await dispatch(tvFetchEpisodes({ series_id })) : await dispatch(tvFetchEpisodes({}));
};

export const tvFetchEpisodes = (values) => async (dispatch, state) => {
  if (!state.submitting) {
    dispatch(setSubmitting(true));
    const res = await axios.post(`/api/tvgetepisodes`, values);
    dispatch({ type: types.FETCH_EPISODES, payload: res.data });
    dispatch(setSubmitting(false));
  }
};

export const resetEpisodes = () => ({
  type: types.FETCH_EPISODES,
  payload: [],
});

export const fetchSeries = (values) => async (dispatch, state) => {
  if (!state.submitting) {
    dispatch(setSubmitting(true));
    const res = await axios.post(`/api/tvgetseries`, values);
    dispatch({ type: types.FETCH_SERIES, payload: res.data });
    dispatch(setSubmitting(false));
  }
};

export const fetchLog = (values) => async (dispatch, state) => {
  if (!state.submitting) {
    dispatch(setSubmitting(true));
    const res = await axios.get(`/api/logs/${values}`);
    const payload = {};
    payload['fileName'] = values;
    payload['values'] = res.data;
    dispatch({ type: types.FETCH_LOGS, payload });
    dispatch(setSubmitting(false));
  }
};

export const deleteSeries = (values) => async (dispatch, state) => {
  if (!state.submitting) {
    dispatch(setSubmitting(true));
    const res = await axios.delete(`/api/series/${values.series_id}`);
    console.log(values);
    dispatch({ type: types.DELETE_SERIES });
    dispatch(fetchSeries({}));
    dispatch(setSubmitting(false));
  }
};

export const deleteMovie = (values) => async (dispatch, state) => {
  if (!state.submitting) {
    dispatch(setSubmitting(true));
    const res = await axios.delete(`/api/movies/${values.movie_id}`);
    dispatch({ type: types.DELETE_MOVIE });
    dispatch(fetchMovies({ seen: false }));
    dispatch(setSubmitting(false));
  }
};

export const deleteEpisode = (values) => async (dispatch, state) => {
  if (!state.submitting) {
    dispatch(setSubmitting(true));
    const res = await axios.delete(`/api/episodes/${values.episode_id}`);
    dispatch({ type: types.DELETE_EPISODE });
    dispatch(tvFetchEpisodes({ seen: false }));
    dispatch(setSubmitting(false));
  }
};
