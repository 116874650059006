import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ModalVideo from 'react-modal-video';

import { fetchSeries, setLoading, resetEpisodes, deleteSeries } from '../actions';

class Series extends Component {
  state = {
    loadedItems: [],
    videoId: 'oOoZgb7mycg',
    isOpen: false,
  };

  componentDidMount() {
    this.props.resetEpisodes();
    this.props.fetchSeries({});
    this.props.setLoading(true);
  }

  componentWillUnmount() {
    this.props.setLoading(false);
  }

  onLoad(feedItem) {
    this.setState(
      ({ loadedItems }) => {
        return { loadedItems: loadedItems.concat(feedItem) };
      },
      () => {
        if (this.state.loadedItems.length === this.props.series.length) this.props.setLoading(false);
      }
    );
  }

  renderSeries() {
    return (
      <TransitionGroup className='series-list'>
        {this.state.loadedItems.map((serie) => {
          let imageFilename = serie.banner
            ? serie.banner.substring(serie.banner.lastIndexOf('/') + 1)
            : 'no_banner.jpg';
          return (
            <CSSTransition key={serie._id} classNames='fade' timeout={300}>
              <div className='col l4 m6 s12 boxInner'>
                <div className='card'>
                  <div className='card-image waves-effect waves-block waves-light hoverable'>
                    <Link to={`/dashboard/series/${serie._id}`} className='waves-effect'>
                      <img
                        className='responsive-img scale-transition'
                        src={`/api/images/${imageFilename}`}
                        alt={serie.name}
                      />
                    </Link>
                    {serie.trailer ? (
                      <a
                        className='btn-floating responsive-img red btn-small waves-effect'
                        onClick={() => {
                          this.setState({
                            videoId: serie.trailer.split('?v=')[1],
                            isOpen: true,
                          });
                        }}
                      >
                        <i className='material-icons'>movie</i>
                      </a>
                    ) : (
                      <div />
                    )}
                    <a
                      className='btn-floating-bottom responsive-img red waves-effect'
                      onClick={() => {
                        this.props.deleteSeries({ series_id: serie._id });
                      }}
                    >
                      <i className='material-icons'>delete</i>
                    </a>
                  </div>
                </div>
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    );
  }

  render() {
    return (
      <div className='row pad right-align'>
        <ModalVideo
          channel='youtube'
          isOpen={this.state.isOpen}
          videoId={this.state.videoId}
          onClose={() => this.setState({ isOpen: false })}
        />
        {this.renderSeries()}
        <div className='hideme'>
          {this.props.series.map((serie) => {
            let imageFilename = serie.banner.substring(serie.banner.lastIndexOf('/') + 1);

            if (!serie.seen || this.state.showSeen) {
              return (
                <img
                  src={`/api/images/${imageFilename}`}
                  alt={imageFilename}
                  key={serie._id}
                  onLoad={this.onLoad.bind(this, serie)}
                />
              );
            } else return <div key={serie._id} />;
          })}
        </div>
      </div>
    );
  }
}

export default connect(
  ({ series }) => ({ series }),
  {
    fetchSeries,
    setLoading,
    resetEpisodes,
    deleteSeries,
  }
)(Series);
