import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import { registerUser } from "../actions";
import { LOGIN_FORM_COLOR, LOGIN_BUTTON_COLOR } from "../constants";

class Config extends Component {
  renderContent = () => {
    const { registerUser } = this.props;
    return (
      <div className="pad">
        <div className="row">
          <div className="col l4 s10">
            <div className={`card ${LOGIN_FORM_COLOR}`}>
              <div className="card-content white-text">
                <span className="card-title">Register New User</span>
                <form
                  onSubmit={this.props.handleSubmit(values =>
                    registerUser(values)
                  )}
                >
                  <Field
                    type="text"
                    style={{
                      WebkitBoxShadow: "0 0 0 30px #26a69a inset"
                    }}
                    name="username"
                    placeholder="Username"
                    component="input"
                  />
                  <Field
                    type="password"
                    style={{
                      WebkitBoxShadow: "0 0 0 30px #26a69a inset"
                    }}
                    name="password"
                    placeholder="Password"
                    component="input"
                  />
                  <button
                    className={`btn ${LOGIN_BUTTON_COLOR} black-text waves-effect waves-light right`}
                    type="submit"
                  >
                    Register
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.props.auth ? (
      this.renderContent()
    ) : (
      <div>You are not logged in!</div>
    );
  }
}

Config = connect(({ auth }) => ({ auth }), { registerUser })(Config);

export default reduxForm({
  form: "registerForm"
})(Config);
