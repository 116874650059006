import React from "react";

const Landing = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>FlexUI</h1>
      A Flexget UI made by me
    </div>
  );
};

export default Landing;
