import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import M from 'materialize-css/dist/js/materialize.min.js';
import lo from 'lodash';

import { saveSeries, tvFetchEpisodes, setLoading } from '../actions';

class Episodes extends Component {
  state = { serie: {}, showSeen: false, loadedItems: [] };

  componentWillMount() {
    if (this.props.match.params.id === undefined) {
      this.props.tvFetchEpisodes({ seen: false });
    } else {
      this.setState({ showSeen: true }, () => {
        this.props.tvFetchEpisodes({ series_id: this.props.match.params.id });
      });
    }
  }

  componentDidMount() {
    this.setState({ loadedItems: [] }, () => this.props.setLoading(true));
    var elem = document.querySelector('.sidenav-descr');
    M.Sidenav.init(elem, {
      edge: 'right',
      inDuration: 250
    });
  }

  componentWillUnmount() {
    this.props.setLoading(false);
  }

  onLoad(feedItem) {
    this.setState(
      ({ loadedItems }) => {
        return { loadedItems: loadedItems.concat(feedItem) };
      },
      () => {
        if (this.state.loadedItems.length === this.props.episodes.length)
          this.props.setLoading(false);
      }
    );
  }

  removeLoaded(item) {
    this.setState(({ loadedItems }) => {
      return {
        loadedItems: lo.remove(this.state.loadedItems, x => x !== item)
      };
    });
  }

  renderEpisodes() {
    return (
      <TransitionGroup className='episodes-list'>
        {this.state.loadedItems.map(serie => {
          let imageFilename = serie.banner
            ? serie.banner.substring(serie.banner.lastIndexOf('/') + 1)
            : 'no_banner.jpg';

          if (!serie.seen || this.state.showSeen) {
            return (
              <CSSTransition key={serie._id} classNames='fade' timeout={300}>
                <div className='col l4 m6 s12 boxInner'>
                  <div className='card'>
                    <div className='card-image waves-effect waves-block waves-light hoverable'>
                      <img
                        className='responsive-img scale-transition sidenav-trigger'
                        data-target='descr-nav'
                        src={`/api/images/${imageFilename}`}
                        alt={serie.name}
                        onClick={() =>
                          this.setState({
                            serie
                          })
                        }
                      />

                      <a
                        className='responsive-img btn-floating btn-large waves-effect waves-light red right btn-small'
                        onClick={() => {
                          this.props.saveSeries(
                            serie,
                            this.props.match.params.id ? true : false
                          );
                          if (this.state.showSeen) {
                            this.removeLoaded(serie);
                          } else this.removeLoaded(serie);
                        }}
                      >
                        <i className='material-icons'>
                          {serie.seen ? 'add' : 'done'}
                        </i>
                      </a>
                      <div className='result-details'>
                        S{serie.season || ''} E{serie.episode || ''}
                      </div>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            );
          } else return <div key={serie._id} />;
        })}
      </TransitionGroup>
    );
  }

  render() {
    const { serie } = this.state;

    return (
      <div className='row pad'>
        <div className='left-align' />
        <div className='right-align'>
          <label style={{ paddingRight: '10px' }}>
            <input
              type='hidden'
              style={{ visibility: 'hidden' }}
              className='filled-in'
              onChange={() => {
                this.setState({ showSeen: !this.state.showSeen });
                this.props.tvFetchEpisodes({});
              }}
              checked={this.state.showSeen ? 'checked' : ''}
            />
            {/*<span>Show Watched</span>*/}
          </label>
          <div className='row'>
            {this.renderEpisodes()}
            <div className='hideme'>
              {this.props.episodes.map(serie => {
                let imageFilename = serie.banner.substring(
                  serie.banner.lastIndexOf('/') + 1
                );

                if (!serie.seen || this.state.showSeen) {
                  return (
                    <img
                      src={`/api/images/${imageFilename}`}
                      alt={imageFilename}
                      key={serie._id}
                      onLoad={this.onLoad.bind(this, serie)}
                    />
                  );
                } else return <div key={serie._id} />;
              })}
            </div>
            <ul id='descr-nav' className={`sidenav sidenav-descr`}>
              <li className='card-title'>{serie.name || ''}</li>
              <li className='card-title'>{serie.title || ''}</li>
              <li>
                <div className='divider' />
              </li>
              <li className='desc-text card-content'>
                <p className='right-align'>
                  Season {serie.season || ''} Episode {serie.episode || ''}
                </p>
              </li>
              <li>
                <div className='divider' />
              </li>
              <li>
                <p className='desc-text'>{serie.plot || ''}</p>
              </li>
              <li>
                <div className='divider' />
              </li>
              <li>
                <p className='desc-text right'>
                  Date added: {serie.date ? serie.date.substring(0, 10) : ''}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ episodes }) => ({ episodes }), {
  saveSeries,
  tvFetchEpisodes,
  setLoading
})(Episodes);

//TODO: Pagination some day???
//TODO: Logs rotation in debian
