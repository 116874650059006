import { combineReducers } from "redux";

import submittingReducer from "./submittingReducer";
import loadingReducer from "./loadingReducer";
import authReducer from "./authReducer";
import moviesReducer from "./moviesReducer";
import episodesReducer from "./episodesReducer";
import seriesReducer from "./seriesReducer";
import logsReducer from "./logsReducer";
import { reducer as formReducer } from "redux-form";

export default combineReducers({
  auth: authReducer,
  form: formReducer,
  submitting: submittingReducer,
  movies: moviesReducer,
  episodes: episodesReducer,
  series: seriesReducer,
  logs: logsReducer,
  loading: loadingReducer
});
