import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";

import { fetchUser } from "../actions";
import Header from "./Header";
import Landing from "./Landing";
import Login from "./Login";
import Dashboard from "./Dashboard";
import Movies from "./Movies";
import Episodes from "./Episodes";
import Series from "./Series";
import Logs from "./Logs";
import Config from "./Config";

import { BODY_COLOR } from "../constants";
//@import 'node_modules/react-modal-video/scss/modal-video.scss';

class App extends Component {
  componentDidMount() {
    document.body.style.backgroundColor = BODY_COLOR;
    this.props.fetchUser();
  }

  render() {
    return (
      <div>
        <BrowserRouter basename="/">
          <div>
            <Header />
            <Route exact path="/" component={Landing} />
            <Route path="/dashboard" component={Dashboard} />
            <Route exact path="/dashboard/movies" component={Movies} />
            <Route exact path="/dashboard/episodes" component={Episodes} />
            <Route exact path="/dashboard/series" component={Series} />
            <Route exact path="/dashboard/series/:id" component={Episodes} />
            <Route exact path="/dashboard/logs" component={Logs} />
            <Route exact path="/dashboard/config" component={Config} />
            <Route exact path="/login" component={Login} />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default connect(
  ({ auth }) => ({ auth }),
  { fetchUser }
)(App);
