import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { submitCredentials } from "../actions";

import { LOGIN_FORM_COLOR, LOGIN_BUTTON_COLOR } from "../constants";

class Login extends Component {
  render() {
    const { submitCredentials, history } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col l4 offset-l4 s10 offset-s1">
            <div className={`card ${LOGIN_FORM_COLOR}`}>
              <div className="card-content white-text">
                <span className="card-title">Log in</span>
                <form
                  onSubmit={this.props.handleSubmit(values =>
                    submitCredentials(values, history)
                  )}
                >
                  <Field
                    type="text"
                    style={{
                      WebkitBoxShadow: "0 0 0 30px #26a69a inset"
                    }}
                    name="username"
                    placeholder="Username"
                    component="input"
                  />
                  <Field
                    type="password"
                    style={{
                      WebkitBoxShadow: "0 0 0 30px #26a69a inset"
                    }}
                    name="password"
                    placeholder="Password"
                    component="input"
                  />
                  <button
                    className={`btn ${LOGIN_BUTTON_COLOR} black-text waves-effect waves-light right`}
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login = connect(null, { submitCredentials })(withRouter(Login));

export default reduxForm({
  form: "loginForm"
})(Login);
