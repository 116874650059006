import { FETCH_MOVIES, DELETE_MOVIE } from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_MOVIES:
      return action.payload || false;
    case DELETE_MOVIE:
      return state;
    default:
      return state;
  }
};
