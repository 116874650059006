import React, { Component } from "react";
import { connect } from "react-redux";
import lo from "lodash";

import { fetchLog } from "../actions";
import { LOG_FILES } from "../constants";

class Logs extends Component {
  state = { selected: LOG_FILES[0] };

  componentDidMount() {
    lo.forEach(LOG_FILES, logFile => this.props.fetchLog(logFile));
  }

  render() {
    const { logs } = this.props;

    return (
      <div className="row pad">
        <div className="col s12 m4 l2" style={{ marginTop: "9px" }}>
          {lo.map(LOG_FILES, logFile => (
            <a
              key={logFile}
              className="waves-effect waves-light btn"
              style={{ width: "100%" }}
              onClick={() => this.setState({ selected: logFile })}
            >
              {logFile}
            </a>
          ))}
        </div>
        <div className="col s12 m8 l10">
          <div className="card blue-grey darken-1">
            <div
              className="card-content white-text"
              style={{ whiteSpace: "pre-line" }}
            >
              <span className="card-title">{this.state.selected}</span>
              <p>{logs[this.state.selected]}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ logs, submitting }) => ({ logs, submitting }),
  { fetchLog }
)(Logs);
