export const EXPRESS_URL = "127.0.0.1:5000";
export const LOG_FILES = [
  "mongoInsert.log",
  "moviesAccepted.nfo",
  "TVAccepted.nfo",
  "flexget.log"
];

//---------------------  STYLING -------------------------
export const NAV_COLOR = "red lighten-1";
export const SIDENAV_COLOR = "orange lighten-3";
export const BODY_COLOR = "#fffad8";
export const LOGIN_FORM_COLOR = "teal lighten-1";
export const LOGIN_BUTTON_COLOR = "red lighten-1";
export const SECOND_SIDENAV_COLOR = "#fff195";
