import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { NAV_COLOR } from "../constants";

class Header extends Component {
  renderContent = () => {
    switch (this.props.auth) {
      case null:
        return;
      case false:
        return (
          <li>
            <Link to={"/login"}>Login</Link>
          </li>
        );
      default:
        return (
          <div>
            <li>
              <Link to={"/dashboard/movies"}>Dashboard</Link>
            </li>
            <li>
              <a href="/api/logout">Logout</a>
            </li>
          </div>
        );
    }
  };

  render() {
    return (
      <div className="navbar-fixed">
        <nav
          className={`${NAV_COLOR}`}
          style={{ height: "64px", position: "fixed" }}
        >
          <div className="nav-wrapper">
            <Link
              to={this.props.user ? "/dashboard" : "/"}
              className="left brand-logo"
            >
              FlexUI
              {this.props.submitting === true || this.props.loading === true ? (
                <div id="loader-wrapper">
                  <div id="loader" />
                </div>
              ) : (
                ""
              )}
            </Link>

            <ul className="right">{this.renderContent()}</ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default connect(({ auth, submitting, loading }) => ({
  auth,
  submitting,
  loading
}))(Header);
